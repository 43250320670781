import { Controller } from "stimulus"

import Popper from 'popper.js'

export default class extends Controller {
  static targets = [ "button", "dialog" ]

  connect() {
    var reference = this.element.querySelector('.popover_button')
    var popper    = this.element.querySelector('.popover')
    this.popperInstance = new Popper(reference, popper, {
      placement: (this.data.get('position') || 'bottom-start')
    })
    document.addEventListener('turbolinks:before-cache', function(event) {
      popper.classList.remove('is-open');
    })
  }

  toggle() {
    if (this.dialogTarget.classList.contains('is-open')) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.dialogTarget.classList.add('is-open')
    this.popperInstance.scheduleUpdate()
  }

  // https://discourse.stimulusjs.org/t/hide-a-popup-on-clicking-outside-the-popup-area/67/8
  close(event) {
    // changed your solution with crispinheneise's recommendation and added additional check:
    if (event && (this.dialogTarget.contains(event.target) || this.buttonTarget.contains(event.target))) {
      // event.preventDefault();
      return;
    }

    // this.buttonTarget.setAttribute('aria-expanded', 'false');
    // this.buttonTarget.classList.remove('is-active');
    this.dialogTarget.classList.remove('is-open');
  }
}
