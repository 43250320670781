// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Stylesheets
require('normalize.css/normalize.css');
require('stylesheets/application.sass');

// JS

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faCopyright } from '@fortawesome/free-solid-svg-icons/faCopyright'
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode'
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faUserMinus } from '@fortawesome/free-solid-svg-icons/faUserMinus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'

library.add(faSearch)
library.add(faCaretUp)
library.add(faCaretDown)
library.add(faTimes)
library.add(faCopyright)
library.add(faCode)
library.add(faBolt)
library.add(faUserPlus)
library.add(faUserEdit)
library.add(faUserMinus)
library.add(faUpload)

dom.watch()

// Turbolinks
document.addEventListener('turbolinks:before-cache', function(event) {
  var el = document.querySelector('.notification-section')
  if (el) {
    el.remove()
  }
})

// Stimulus
import "controllers/application"
