import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "switch" ]

  connect() {
  }

  toggle() {
    var select = event.currentTarget

    var filter_id = select.options[select.selectedIndex].value
    var filters = this.element.querySelectorAll(".filter-group > [data-filter]")
    var filter = this.element.querySelector(`.filter-group > [data-filter='${filter_id}']`)
    var ignore_id = filter.dataset.ignore

    // Hide and disable inputs for unselected filters
    for (var i=0; i < filters.length; i++) {
      filters[i].classList.add('is-hidden')
      var disabled_filter_inputs = filters[i].querySelectorAll(`input, select:not([data-target='search_filter.switch'])`)
      for (var j=0; j < disabled_filter_inputs.length; j++) {
        disabled_filter_inputs[j].disabled = true
      }
    }

    // Show and enable inputs for selected filter
    filter.classList.remove('is-hidden')
    var enabled_filter_inputs = filter.querySelectorAll('input, select')
    for (var i=0; i < enabled_filter_inputs.length; i++) {
      enabled_filter_inputs[i].disabled = false
    }

    var enabled_hidden_inputs = this.element.querySelectorAll(".filter-form > input[type='hidden']")
    for (var i=0; i < enabled_hidden_inputs.length; i++) {
      enabled_hidden_inputs[i].disabled = false
    }
    var disabled_hidden_inputs = this.element.querySelectorAll(`.filter-form > input[type='hidden'][name='${ignore_id}'], .filter-form > input[type='hidden'][name='${filter_id}']`)
    for (var i=0; i < disabled_hidden_inputs.length; i++) {
      disabled_hidden_inputs[i].disabled = true
    }
  }
}
